import { SponsorshipDataModel } from '../model/data.model';

export const SponsorshipData: SponsorshipDataModel[] = [
  {
    level: 'diamond',
    website: 'https://www.amdocs.com/',
    logo: 'amdoc_make_it_amazing_logo.png',
    name: 'Amdocs',
    description:
      "Amdocs helps those who build the future to make it amazing. In an era where new technologies are born every minute, and the demand for meaningful digital experiences has never been so intense, Amdocs unlock their customer' innovative potential, empowering them to transform their boldest ideas into reality, and make billions of people feel like VIPs. Our 31,000 employees around the globe are here to accelerate our customers' migration to the cloud, differentiate in the 5G era, digitalize and automate their operations, and provide end users with the next-generation communication and media experiences that make the world say wow. Listed on NASDAQ, Amdocs had revenue of $4,5 billion in fiscal 2022.",
  },
  {
    level: 'diamond',
    website: 'https://www.huawei.com/za/',
    logo: 'huawei-vertical-logo.png',
    name: 'Huawei',
    description:
      'Founded in 1987, Huawei is a leading global provider of information and communications technology (ICT) infrastructure and smart devices. We have 207,000 employees and operate in over 170 countries and regions, serving more than three billion people around the world. We are committed to bringing digital to every person, home and organization for a fully connected, intelligent world.',
  },
  {
    level: 'diamond',
    website: 'https://www.nokia.com/',
    logo: 'nokia-logo.png',
    name: 'Nokia',
    description:
      'At Nokia, we create technology that helps the world act together. As a B2B technology innovation leader, we are pioneering networks that sense, think and act by leveraging our work across mobile, fixed and cloud networks. In addition, we create value with intellectual property and long-term research, led by the award-winning Nokia Bell Labs. Service providers, enterprises and partners worldwide trust Nokia to deliver secure, reliable and sustainable networks today, and work with us to create the digital services and applications of the future.',
  },
  {
    level: 'diamond',
    website: 'https://connect.openserve.co.za/',
    logo: 'openserve_logo.png',
    name: 'Openserve',
    description:
      "Openserve is South Africa's largest telecommunications infrastructure provider with the biggest fixed broadband network in the country. Our brand embodies the ethos of open access connectivity for all and as South Africa's National connectivity leader, we connect South Africa to the rest of the world. We offer high quality products and services, while ensuring that we put the client at the centre of everything we do.",
  },
  {
    level: 'diamond',
    website: 'https://www.telkom.co.za/',
    logo: 'telkom-logo.png',
    name: 'Telkom',
    description:
      'Telkom is a leading Information and Communications Technology (ICT) Service Provider in South Africa, who strives to seamlessly connect people to a better life through bridging the digital divide, whether at a consumer or business level. Telkom Consumer and Small Business creates innovative and pervasive broadband services and solutions to meet the needs of diverse lifestyles, customer segments and businesses, leveraging a quality network through bringing the best value, convenience, and transformative technology.',
  },
  {
    level: 'platinum',
    website: 'https://ztedevices.com/en-sa/',
    logo: 'zte-logo.png',
    name: 'ZTE',
    description:
      'ZTE is a Global Leading Integrated Communication Information Solution Provider. With innovative technologies and product solutions, ZTE serves global telecom operators, government and enterprise customers, and consumers. Covering more than 160 countries and regions, ZTE serves over 1/4 people worldwide, and is committed to achieving a bright future of connectivity and trust everywhere.',
  },
  {
    level: 'insights',
    website: 'https://www.deloitte.com/za/en.html',
    logo: 'deloitte-logo.png',
    name: 'Deloitte',
    description:
      'Deloitte provides industry-leading audit and assurance, tax and legal, consulting, financial advisory and risk advisory services to nearly 90% of the Fortune Global 500® and thousands of private companies. Our professionals deliver measurable and lasting results that help reinforce public trust in capital markets, enable clients to transform and thrive, and lead the way toward a stronger economy, a more equitable society and a sustainable world. Building on its 175-plus year history, Deloitte spans more than 150 countries and territories. Learn how Deloitte’s more than 457 000 people worldwide make an impact that matters at www.deloitte.com',
  },
  {
    level: 'platinum',
    website: 'https://www.accenture.com/za-en',
    logo: 'accenture_logo.png',
    name: 'Accenture',
    description:
      'Our purpose is to deliver on the promise of technology and human ingenuity - by embracing the power of change to create 360-degree value for our clients, people and communities.',
  },
  // {
  //   level: 'gold',
  //   website: 'https://www.ey.com/en_za',
  //   logo: 'ey-logo.png',
  //   name: 'EY',
  //   description:
  //     "EY exists to build a better working world, helping to create long-term value for clients, people and society and build trust in the capital markets. Enabled by data and technology, diverse EY teams in over 150 countries provide trust through assurance and help clients grow, transform and operate. Working across assurance, consulting, law, strategy, tax and transactions, EY teams ask better questions to find new answers for the complex issues facing our world today. We believe a better working world is one where economic growth is sustainable and inclusive. We work continuously to improve the quality of all our services, investing in our people and innovation. And we're proud to work with others - from our clients to wider stakeholders - to use our knowledge, skills and experience to help fulfill our purpose and create positive change.",
  // },
  {
    level: 'gold',
    website: 'https://www.falcorp.co.za/',
    logo: 'falcorp_logo_with_brandmark.svg',
    name: 'Falcorp Technologies',
    description:
      "Falcorp Technologies is a Digital Technology organisation. We endeavour to be amongst the most innovative solution providers in South Africa through the digital empowerment of individuals and organisations. Our true value lies in our people. Transparency, accountability, trust, and real relationships with our clients - and with each other - have been the cornerstone of our company since its humble beginnings more than a decade ago. We invest in what we do, treating every brand as if it were our own - after all, our client's success is our reputation.",
  },
  {
    level: 'silver',
    website: 'https://sgtsolutions.co.za/',
    logo: 'sgt_logo.png',
    name: 'SGT Solutions',
    description:
      'SGT Solutions is a turnkey solutions integrator specialising in the design, supply, deployment, commissioning and maintenance of multi-technology telecommunication systems for mobile broadband and converged solutions, through partnerships with our customers and technology providers.',
  },
  // {
  //   level: 'silver',
  //   website: 'https://weinert-industries.com/',
  //   logo: 'weinert_logo.png',
  //   name: 'WEINERT Industries',
  //   description:
  //     'We deliver light - no matter how challenging it is. Once, or millions of times. For more than 20 years we have been involved in the development, design & production of fibre optic products. WEINERT Industries is a leading family-owned provider of ultrapure fused silica, preforms & rods, as well as optical fibres, cables, assemblies, & special components. We offer a unique product portfolio at every stage along the value chain: from ultrapure fused silica to preform & the drawn fibres through to copper, hybrid fibre optical cables & complete fibre optic systems with optical components developed in-house. We can optimize many factors independently according to customer requirements. With facilities in Germany, South Africa, USA, China, Hong Kong & Singapore we can service customers globally. We at WEINERT Industries believe in the power of innovation, creativity and capabilities of our employees, as well as in our long lasting & mutually beneficial Customer relationships.',
  // },
  {
    level: 'gold',
    website: 'https://www.csgi.com/products/xponent/',
    logo: 'csg_logo.png',
    name: 'CSG',
    description:
      "CSG empowers companies to build unforgettable experiences, making it easier for people and businesses to connect with, use and pay for the services they value most. Our customer experience and billing solutions help companies of any size make money and make a difference. Astounding outcomes. Accelerated with CSG Xponent Ignite, our customer experience solution for the telco industry. Show up for your customers in the moments that matter most and make every experience effortless. Integrate systems and channels of communication to offer personalized experiences from activation of service to bill inquiries, so it's easier than ever to do business with you.",
  },
];
