import { SpeakerDataModel } from "../model/data.model";

export const SpeakerData: SpeakerDataModel[] = [
  // {
  //   profileImage: 'assets/home/2022/SerameTaukobong.jpg',
  //   name: 'Serame',
  //   surname: 'Taukobong',
  //   position: 'Group Chief Executive Officer',
  //   company: 'Telkom SA',
  //   bio: 'Serame was appointed GCEO Designate in August 2021.  He has taken over the role of Telkom’s Group Chief Executive Officer on 1 January 2022. Serame was appointed at Telkom SA in June 2018, as the Chief Executive Officer of the Consumer Business Unit, taking responsibility for both its Mobile and Fixed Consumer businesses.'
  // },
  // {
  //   profileImage: 'assets/home/PhillyMapulane.jpg',
  //   name: 'Philly',
  //   surname: 'Mapulane',
  //   position: 'Deputy Minister of Communications & Digital Technologies, South Africa',    
  //   bio: 'Mohlopi Phillemon “Philly” Mapulane was appointed by the President of the Republic of South Africa, His Excellency President Cyril Ramaphosa, on the 5th of August 2021 as the Deputy Minister of Communications & Digital Technologies.  Deputy Minster Mapulane is a seasoned local government practitioner who spent a period of 14 years in Local Government Management and Administration. Ten of those years he occupied Senior Management positions.'
  // },
  // {
  //   profileImage: 'assets/home/ThomasAfullo.jpg',
  //   name: 'Prof. Thomas JO',
  //   surname: 'Afullo',
  //   position: 'Emeritus Professor',
  //   company: 'University of KwaZulu Natal',
  //   bio: 'Prof. Thomas Afullo holds a BSc in Electrical Engineering (Nairobi University); the MSEE (West Virginia University); and the Doctor of Applied Sciences (VUB, Belgium). He worked in the Telecommunications Industry, leaving as Senior Executive Engineer to join Academia. He then lectured at Moi University, Kenya; University of Botswana; and University of KwaZulu-Natal, where he is Emeritus Professor. He is a Fellow of South African Institute of Electrical Engineers (SAIEE); Fellow of South African Academy of Engineering (SAAE); Senior Member of IEEE; and registered Professional Engineer with ECSA.'
  // },
  // {
  //   profileImage: 'assets/home/ColinCampbell.jpg',
  //   name: 'Colin',
  //   surname: 'Campbell',
  //   position: 'Executive Director: Customer Engagement',
  //   company: 'CSG',
  //   bio: 'As an Executive Director, Colin helps to lead our Customer Experience go to market program for a wide range of our solution into core vertical and international markets. He has over 20 years of experience building & launching enterprise solutions that deliver extraordinary customer experiences. Prior to CSG, Colin held leadership roles in Product, Marketing, and Product Strategy at Aravo, SAI360 & Everbridge, helping customers solve some of their biggest business challenges across APJ, USA/Canada, UK and EMEA.'
  // },
  // {
  //   profileImage: 'assets/home/2022/EmmanuelDeLaGardette.jpg',
  //   name: 'Emmanuel',
  //   surname: 'de la Gardette',
  //   position: 'Director & CTO',
  //   company: 'ZTE Group',
  //   bio: 'Emmanuel de la Gardette joined ZTE at the beginning of 2011 after 20+ years in international IT companies such as Sun Microsystems, Oracle and Andersen Consulting, where he held Managing and Engineering positions.'
  // },
  // {
  //   profileImage: 'assets/home/HarrisonLi.jpg',
  //   name: 'Harrison',
  //   surname: 'Li',
  //   position: 'Chief Technology Officer',
  //   company: 'Huawei Cloud Southern Africa',
  //   bio: 'Harrison has been with Huawei Cloud since 2019. Prior to joining Huawei, he worked as the Technical Account Director for AWS covering the APEX countries. Within his current role he is the CTO for Huawei Cloud in the Africa Region and focuses on OpenSource, Big Data and Containers. Harrison actively supports all the SA and Africa Solution Architects with solution designs and advice.'
  // },
  // {
  //   profileImage: 'assets/home/PushkarGokhale.jpg',
  //   name: 'Pushkar',
  //   surname: 'Gokhale',
  //   position: 'Chief Strategy & Digital Officer',
  //   company: 'Openserve',
  //   bio: 'Pushkar is a senior leader with global experience across strategy and technology. As the Chief Digital and Strategy Officer, Openserve, Pushkar holds a diverse portfolio, which includes business and technology (IT and Network) strategy; and oversees the execution of strategic initiatives across the organisation.'
  // },
  // {
  //   profileImage: 'assets/home/MmakiJantjies.jpg',
  //   name: 'Dr. Mmaki',
  //   surname: 'Jantjies',
  //   position: 'Group Executive: Innovation & Transformation',
  //   company: 'Telkom SA & SATNAC 2023 Chairperson',
  //   bio: 'Dr. Jantjies is the Group Executive, Head of Innovation and Transformation at the South African Telecommunications Provider, Telkom. She joined Telkom in April 2021 to establish the new Innovation Office, focusing on leading digital innovation as well as research and development initiatives of the Telkom Group. Mmaki is also an Adjunct Associate Professor at a South African University.'
  // },
  // {
  //   profileImage: 'assets/home/AkiAnastasiou.jpg',
  //   name: 'Aki',
  //   surname: 'Anastasiou',
  //   position: 'Journalist & Technology Evangelist and SATNAC 2023 Programme Director',
  //   bio: 'With over three decades in the media, spanning across radio, print and television, Aki Anastasiou has seen the evolution of technology and the changes it has brought to society through different lenses. As one South Africa’s top futurists, technology influencers, facilitators and conference hosts, he thrives on innovation and digital transformation, and the incredible impact it is having on global citizens and business.'
  // },
  // {
  //   profileImage: 'assets/home/MaletsabisaMolapo.jpg',
  //   name: 'Dr. Maletšabisa (Tšabi)',
  //   surname: 'Molapo',
  //   position: 'Executive: Research & Innovation',
  //   company: 'Telkom SA',
  //   bio: 'Dr. Molapo is the Head of Research and Innovation at Telkom SA. She leads Telkom\'s Research & Development (R&D) Portfolio, which includes the Telkom Centres of Excellence Programme and other Research-led Innovation Functions. Prior to joining Telkom SA, she worked at IBM Research as a Research Manager, leading Artificial Intelligence and Quantum Computing and a Product Manager for Accelerated Discovery. Her recent research has spanned areas such as Trustworthy AI, Natural Language Processing, IoT for Healthcare and AI for Education. She also has extensive experience in User Experience Research, Service Design, Product Strategy and Human Computer Interaction.'
  // },
  // {
  //   profileImage: 'assets/home/OfirDaniel.jpg',
  //   name: 'Ofir',
  //   surname: 'Daniel',
  //   position: 'Head of Platform Marketing',
  //   company: 'Amdocs',
  //   bio: 'Ofir has 20+ years\' experience in the Communications & Media domains, leading new platforms & technologies to market and always hungry for innovation.'
  // },
  // {
  //   profileImage: 'assets/home/PatrickSekgoala.jpg',
  //   name: 'Dr. Patrick',
  //   surname: 'Sekgoka',
  //   position: 'Executive: Marketing & Data Science',
  //   company: 'Telkom Consumer',
  //   bio: 'Patrick leads Telkom Consumer & Small Business on data-driven insights for strategic decision-making. He is an award-winning Data Scientist with over 20 years experience spanning several industries, including Commercial & Central Banking, Market Research and Consulting. He holds a Ph.D. Degree in Industrial & Systems Engineering from the University of Pretoria and an international Master of Science Degree in Engineering Mathematics from the University of Twente (The Netherlands).'
  // },
  // {
  //   profileImage: 'assets/home/2022/AnaBarbosa.jpg',
  //   name: 'Ana',
  //   surname: 'Barbosa',
  //   position: 'Digital Product Designer Lead',
  //   company: 'CSG',
  //   bio: 'Ana is a digital product designer with over a decade of shaping operations and experiences for fintech, gaming, and telcos in the US, EMEA, and Latin America - working for companies like UOL Pagseguro, allyouplay, and CSG. Ana is passionate about creating, shaping, and optimizing experiences that put the human factor at the forefront of technology and business. For her, design is a powerful way to channel our curiosity and creativity to think about, react to, and solve problems.'
  // },
  // {
  //   profileImage: 'assets/home/2022/ArunBabu.jpg',
  //   name: 'Arun',
  //   surname: 'Babu',
  //   position: 'Managing Director',
  //   company: 'Deloitte Consulting Africa',
  //   bio: 'Arun Babu is the Managing Director/ CEO of Deloitte Consulting Africa, leading the Consulting business across Southern, East and West Africa. He also focuses on working with clients on business transformation initiatives and new-age digital solutions. Arun brings together strategy, business operations and digital technology experiences for his clients and has extensive experience in growing businesses and leading large teams.'
  // },
  // {
  //   profileImage: 'assets/home/2022/AzaniaMosaka.jpg',
  //   name: 'Azania',
  //   surname: 'Mosaka',
  //   position: 'Programme Director',    
  //   bio: 'Azania Mosaka is a South African broadcaster, journalist, radio and television personality, businesswoman, producer and philanthropist. Over the years, she has built a reputation as a trusted presenter, MC, brand ambassador, podcaster and voice over artist. Winner of the Style Award, Azania brings authenticity, professionalism and warmth to all her collaborations.'
  // },
  // {
  //   profileImage: 'assets/home/2022/CharleneNaidoo.jpg',
  //   name: 'Charlene',
  //   surname: 'Naidoo',
  //   position: 'Executive: Technology Development &amp; Innovation',
  //   company: 'Openserve',      
  //   bio: 'Charlene has extensive business and technical knowledge of the Telco Industry with 15 years of experience, ranging from Technical Product Development across Technical Regulations and Legal Affairs, to Unified Communications Product Portfolio Management.'
  // },
  // {
  //   profileImage: 'assets/home/MariusMostert.jpg',
  //   name: 'Marius',
  //   surname: 'Mostert',
  //   position: 'Independent Consultant & Chairman',
  //   company: 'MCORP Group',
  //   bio: 'Marius Mostert is the Founder of MCORP Communications and Chairman and CTO of the MCORP Group. He also works in the Industry as an Independent Consultant. He has been in telecoms sector for 50 years and holds a B.Eng Electronic Engineering Degree from the University of Stellenbosch and a B.Comm Degree from the University of South Africa.  He also studied Technology Management at the Massachusetts Institute of Technology (MIT) in Boston.'
  // },
  // {
  //   profileImage: 'assets/home/2022/SiyabongaMahlangu.jpg',
  //   name: 'Dr. Siyabonga',
  //   surname: 'Mahlangu',
  //   position: 'Group Executive: Regulatory Affairs &amp; Government Relations',
  //   company: 'Telkom SA',
  //   bio: 'Dr Mahlangu is responsible for the Group\'s strategy and approach to regulation. His area of responsibilities stretches over technical regulation, spectrum and international regulation, regulatory economics, policy and regulatory compliance, and government relations.'
  // },
  // {
  //   profileImage: 'assets/home/2022/SupriyaKummamuru.jpg',
  //   name: 'Dr. Supriya',
  //   surname: 'Kummamuru',
  //   position: 'Chief Technology Officer, TCS, Middle East and Africa',
  //   company: 'TCS',
  //   bio: 'Dr. Supriya Kummamuru is the Chief Technology Officer (CTO) of Tata Consultancy Services, Middle East and Africa. Her role revolves around driving the innovation quotient to strengthen the TCS brand across the geography. She is also responsible for collaborations with start-ups and academia, driving tech innovation-led community initiatives, running Agile Innovation Centers with customers, among others.'
  // },
  // {
  //   profileImage: 'assets/home/2022/JacobMunodawafa.jpg',
  //   name: 'Jacob',
  //   surname: 'Munodawafa',
  //   position: 'Executive Secretary &amp; Chief Executive Officer',
  //   bio: 'Jacob, an Engineer by profession, works for the Southern Africa Telecommunications Association (SATA) as the Executive Secretary and Chief Executive Officer and has more than 25 years of experience working in the Telecommunications and Information & Communication Technology Industry.'
  // },
  // {
  //   profileImage: 'assets/home/2022/JeffreyHuang.jpg',
  //   name: 'Jeffrey',
  //   surname: 'Huang',
  //   position: 'Carrier BG Cloud business CTO',
  //   company: 'Huawei',
  //   bio: 'Jeffrey joined Huawei for 6 years, acting as Carrier BG Cloud business CTO. Every year he has provided several Digital transformation journey for Carrier and Enterprise customers.'
  // },
  // {
  //   profileImage: 'assets/home/2022/LeboMasalesa.jpg',
  //   name: 'Lebo',
  //   surname: 'Masalesa',
  //   position: 'Managing Executive: Mobile Network',
  //   company: 'Telkom Consumer',
  //   bio: 'Lebo Masalesa is the Managing Executive for Mobile Network at Telkom. He has over 19 years\' experience in the telecommunications industry. Lebo holds a Post Graduate Diploma in Business Management and a National Diploma in Electrical Engineering.'
  // },
  // {
  //   profileImage: 'assets/home/2022/LizaZouabi.jpg',
  //   name: 'Liza',
  //   surname: 'Zouabi',
  //   position: 'Executive: Group Pricing Compliance &amp; Regulatory Economics',
  //   company: 'Telkom SA',
  //   bio: 'Liza Zouabi is a seasoned economist who started her career as a lecturer in Economics at the University of South Africa in 1992. As an academic she conducted research and published extensively in the areas of development economics and monetary policy.'
  // },
  // {
  //   profileImage: 'assets/home/2022/PhillyMapulane.jpg',
  //   name: 'Mohlopi Phillemon',
  //   surname: 'Mapulane',
  //   position: 'Deputy Minister of Communications and Digital Technologies',
  //   bio: 'Mohlopi Phillemon “Philly” Mapulane was appointed by the President of the Republic of South Africa, His Excellency President Cyril Ramaphosa, on the 05th of August 2021 as the Deputy Minister of Communications and Digital Technologies.'
  // },
  // {
  //   profileImage: 'assets/home/2022/PeterEckert.jpg',
  //   name: 'Peter',
  //   surname: 'Eckert',
  //   position: 'Co-Founder and Chief Experience Officer',
  //   company: 'Projekt202, Amdocs',
  //   bio: 'Peter Eckert is a Customer Experience Design visionary, recognized by many top global companies as one of the best Experience Design leaders. As Chief Experience Officers and Co-Founder of projekt202, Peter is responsible for helping organizations worldwide develop and establish meaningful company-wide holistic experience strategies.'
  // },
  // {
  //   profileImage: 'assets/home/2022/PortiaMaluleke.jpg',
  //   name: 'Portia',
  //   surname: 'Maluleke',
  //   position: 'Chief IT Governance &amp; Risk Officer',
  //   company: 'Openserve',       
  //   bio: 'Portia Maluleke is the Group Executive of IT Governance & Risk at Telkom. Maluleke served in numerous IT executive roles in the financial services, aviation and technology sectors. She serves on various boards and committees, as well as, mentoring professionals and small businesses. Her portfolio covers process optimisation, data governance, information security governance, compliance, risk and audit management.'
  // },            
  // {
  //   profileImage: 'assets/home/CollinDimakatsoMashile.jpg',
  //   name: 'Collin Dimakatso',
  //   surname: 'Mashile',
  //   position: 'Chief Director and Regulatory Policy Development & Research Expert',
  //   company: 'Department of Communications & Digital Technologies',
  //   bio: 'Collin Dimakatso Mashile is the Chief Director and Regulatory Policy Development & Research Expert at the Department of Communications & Digital Technologies, with focus on digital economy and transformation issues. He has a Master of Communications in Management Science from University of Strathclyde Business School, Glasgow, and various certificates in Digital & Data Regulations.  He also holds Harvard Kennedy School Implementing Public Policy Programme & Strategic Management of Regulatory & Enforcement Agencies certificates and has over 25 years experience in the sector.​'
  // },
  // {
  //   profileImage: 'assets/home/SonkePeters.jpg',
  //   name: 'Sönke',
  //   surname: 'Peters',
  //   position: 'Head: MEA Strategy & Technology',
  //   company: 'Nokia',
  //   bio: 'Since 2021, Sönke Peters oversees the Nokia Strategy & Technology for the MEA Market. He has a wealth of experience in the Telecommunications Industry, drawing on 25 years in various Sales, Strategy, Technology and General Management roles, spanning across key customers, countries, regional and global responsibilities, based out of Munich, Prague and Dubai. He was born 1966 in Germany, married, and has three children.'
  // },
  // {
  //   profileImage: 'assets/home/AlphonzoSamuels.jpg',
  //   name: 'Alphonzo',
  //   surname: 'Samuels',
  //   position: 'Independent Consultant & Director',
  //   company: 'ZNOF Investments (Pty) Ltd',
  //   bio: 'A Senior Executive with more than 35 years\' experience in the ICT Industry. He is the Founder and Director of ZNOF Investments (Pty) Ltd. Prior to this, he served as the CEO of Openserve and CTO at Telkom SA.  He also held a number of Senior Management positions, in Planning, Engineering and Operations. Alphonzo played a pivotal role in shaping SATNAC and has served as a Director of IRE and the Chairman.'
  // },
  // {
  //   profileImage: 'assets/home/ChrisChavaranis.jpg',
  //   name: 'Chris',
  //   surname: 'Chavaranis',
  //   position: 'Account Manager: Customer Experience',
  //   company: 'Nokia South Africa',
  //   bio: 'Chris works at Nokia, focussing on Openserve and other Business Units within the Telkom Group. He started his career with Telkom as a Senior Lecturer and following that has been with Nokia for over 30 years in various roles i.e. Training, Product Line Management and Account Management. He has been involved with the Telkom CoE Programme and SATNAC since 2001 and has attended 20 out of 25 SATNAC conferences.'
  // },
  // {
  //   profileImage: 'assets/home/2022/TapiwaChindeka.jpg',
  //   name: 'Tapiwa',
  //   surname: 'Chindeka',
  //   position: 'CoE Engineer: Digital &amp; Strategy',
  //   company: 'Openserve',
  //   bio: 'Tapiwa Chindeka is an Engineer within the Openserve Engineering Centre of Excellence and performs the role of IT Solution Architect for Openserve. She holds an MSc in Computer Science from Rhodes University and previously presented a full paper at SATNAC (2019). Tapiwa is the Social Media Coordinator for SATNAC.'
  // },
  // {
  //   profileImage: 'assets/home/2022/WolframSturm.jpg',
  //   name: 'Wolfram',
  //   surname: 'Sturm',
  //   position: 'Senior Director Regional PLM',
  //   company: 'Nokia',
  //   bio: 'Wolfram Sturm is currently the head of Regional Product Management and Consulting Engineering for Optical Networks in the EMEA region at Nokia. He has received a diploma in Electrical Engineering from Technical University Darmstadt. He was previously the head of the R&D of Optical Cross Connects and Advanced Technologies team in Nokia Optics Business Unit.'
  // }
  // {
  //   profileImage: 'assets/home/ZeblonVilakazi.jpg',
  //   name: 'Prof Zeblon Zenzele',
  //   surname: 'Vilakazi',
  //   position: 'Vice Chancellor & Principal',
  //   company: 'University of Witwatersrand',
  //   bio: 'Prof Zeblon Zenzele Vilakazi was appointed Vice-Chancellor and Principal of Wits University in January 2021. Previously holding the position of Vice-Principal and DVC Research, he brings a wealth of experience, insight and knowledge to the University and the Executive Team. '
  // },
  // {
  //   profileImage: 'assets/home/WendyPienaar.jpg',
  //   name: 'Wendy',
  //   surname: 'Pienaar',
  //   position: 'Director: Innovation & Ventures',
  //   company: 'Deloitte Africa',
  //   bio: 'Wendy Pienaar is a Digital & Innovation Strategy Executive with extensive corporate venture building experience across industries in various African markets. She leads Innovations and Ventures for Deloitte Africa and has a passion for helping corporates with growth opportunities from new product development to impact innovation and new business model development using upcoming technologies.'
  // },
  // {
  //   profileImage: 'assets/home/AshvirSingh.jpg',
  //   name: 'Ashvir',
  //   surname: 'Singh',
  //   position: 'Senior Specialist: Special Projects & Network Quality',
  //   company: 'Openserve',
  //   bio: 'Ashvir is a Senior Engineer with over a decade of experience in the telecommunications industry covering Research & Development, Data Analytics and Network Quality Optimisation. He currently serves as a the Lead for Operational Strategy and spearheads Special Projects to drive efficiencies and shape the future of network operations.'
  // },
  // {
  //   profileImage: 'assets/home/PhilaSithole.jpg',
  //   name: 'Phila',
  //   surname: 'Sithole',
  //   position: 'Head of Industry Research',
  //   company: 'Association of Comms & Technology',
  //   bio: 'Phila has two decades in the Technology Industry space and working in Engineering companies, including multinational such as Siemens, General Electric, Alstom and Thales. He has recently been with the Department of Communications & Digital Technologies (DCDT), leading the implementation of the Presidential Commission on the Fourth Industrial Revolution. Prior this, his positions included Business Development, Systems Engineering, and Programme Management for industries including rail, energy, mining, telecommunications, manufacturing, and air traffic management.'
  // },
  // {
  //   profileImage: 'assets/home/NtsakoBaloyi.jpg',
  //   name: 'Ntsako',
  //   surname: 'Baloyi',
  //   position: 'Africa Lead: Data & AI',
  //   company: 'Accenture Technology',
  //   bio: 'Ntsako is the Africa Lead for Data & AI, as well as The Liquid Studio within Accenture Technology. He holds a PhD in Information Systems from University of Pretoria; Masters, Honours and BSc Degrees in Computer Science from the University of Limpopo and an LLB from UNISA. He is also an admitted Advocate of the High Court of South Africa and an alumnus of the Telkom CoE at UL.'
  // },
  // {
  //   profileImage: 'assets/home/KeneilweGwabeni.jpg',
  //   name: 'Keneilwe',
  //   surname: 'Gwabeni',
  //   position: 'Chief Information Officer',
  //   company: 'Telkom Consumer & Small Business',
  //   bio: 'Keneilwe has 23 years\' work experience in the Information Technology field, with 17 of those years being in IT leadership roles. She has worked in large Energy, Academic, Regulatory, Financial, as well as Banking Institutions and now Telecommunications. She has won the Global CIO 100 recognition in 2021 as well as 2022, which is an award of top 100 Technology Leaders in the world who showcase excellence, resilience, and innovation.'
  // },
  // {
  //   profileImage: 'assets/home/StefanSteffen.jpg',
  //   name: 'Stefan',
  //   surname: 'Steffen',
  //   position: 'Head: Data Insights & Intelligence',
  //   company: 'BCX',
  //   bio: 'Stefan is an experienced Data Science & Business Strategy leader with a demonstrated history of leading Specialist and Operational teams in the Financial Services and Information Technology industries. He has a strong bias towards strategic business engagement underpinned by analytical insight. With experience across the data disciplines, his strength lies in unlocking business value through actionable insight.'
  // },
  // {
  //   profileImage: 'assets/home/MelissaDensmore.jpg',
  //   name: 'Prof. Melissa',
  //   surname: 'Densmore',
  //   position: 'Associate Professor: Computer Science',
  //   company: 'University of Cape Town',
  //   bio: 'Prof. Densmore is a Human-Computer Interaction for Development Researcher, with a specific focus on the uses of new information technology and interfaces for healthcare in Africa. Prior to joining UCT, she completed a post-doc in the Technology for Emerging Markets Group at Microsoft Research in Bangalore, India and did her PhD at the- University of California, Berkeley School of Information.'
  // },
  // {
  //   profileImage: 'assets/home/AdamPantanowitz.jpg',
  //   name: 'Dr. Adam',
  //   surname: 'Pantanowitz',
  //   position: 'Director: Innovation Centre',
  //   company: 'University of Witwatersrand',
  //   bio: 'Dr. Adam Pantanowitz is an iconic Biomedical and Electrical Engineer, well-known for initiating and leading the first group in the world to connect a human brain live and mobile to the internet in a project called the Brainternet – a term he coined. As a Technologist, he enjoys using his Applied Sciences skills to have real-world impact. He has co-founded a number of businesses/social impact projects including AURA, Resolute Education, PRTL Medical, shift.stream, and others.'
  // },
  // {
  //   profileImage: 'assets/home/SajagArora.jpg',
  //   name: 'Sajag',
  //   surname: 'Arora',
  //   position: 'Lead: Consumer & TMT Consulting Africa',
  //   company: 'EY',
  //   bio: 'Sajag has served multiple Telco organisations over the last 18 years, across 20 countries. He leads the Consumer & TMT Consulting Department for EY Africa and is passionate about enabling clients in the Consumer & TMT sector, to transform and thrive in the era digital disruption by seizing opportunities today for sustainable value creation.'
  // }
]