<!-- Footer -->
<div class="container-fluid bg-dark-blue pt-3">
  <div class="container text-center text-sm-start">
    <div class="row text-white py-3">
      <div class="col-12 col-sm-6">
        <h4 class="mb-3"><strong>Contact</strong></h4>
        <p>
          <i
            class="bi bi-envelope"
            style="font-size: 16px; padding-right: 14px"
          ></i>
          <a
            class="text-white text-decoration-none"
            href="mailto:satnac@telkom.co.za"
            >satnac@telkom.co.za</a
          >
        </p>

        <div
          class="d-flex justify-content-center justify-content-sm-start align-items-center gap-4 mt-4 mb-3"
        >
          <a
            class="text-decoration-none text-white"
            href="https://www.linkedin.com/company/satnac/"
            target="_new"
            aria-label="LinkedIn Page"
          >
            <i class="bi bi-linkedin footerSocialIcons"></i>
          </a>
          <a
            class="text-decoration-none text-white"
            href="https://www.facebook.com/SATNAC"
            target="_new"
            aria-label="Facebook Page"
          >
            <i class="bi bi-facebook footerSocialIcons"></i>
          </a>
          <a
            class="text-decoration-none text-white"
            href="https://x.com/SATNACOFFICIAL"
            target="_new"
            aria-label="X Page"
          >
            <i class="bi bi-twitter-x footerSocialIcons"></i>
          </a>
        </div>
      </div>

      <div class="col-12 col-sm-6 text-sm-end align-self-center my-4 mt-sm-0">
        <img
          src="assets/home/hostedByTelkom_logo.png"
          alt="Hosted by Telkom"
          height="24"
        />
      </div>
    </div>
  </div>
</div>
<!-- End of Footer -->
