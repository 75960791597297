// export const environment = {
//   production: true
// };
export const environment = {
  production: true,
  captcha:{
    key:"6Le-OrYaAAAAAC9lDAkzSeZJppoahnqc8OOGoVHb",
    secret: "6Le-OrYaAAAAAIOD38ym-xCMSdr9Jjc0QCz1cwWk"
  }
};
