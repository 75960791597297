import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NgParticlesModule} from "ng-particles";
import { ComponentModule } from '../../components/component.module';


import { PageHomeRoutingModule } from './page-home-routing.module';
import { PageHomeComponent } from './page-home.component';



@NgModule({
  declarations: [
    PageHomeComponent
  ],
  imports: [
    CommonModule,
    PageHomeRoutingModule,
    NgParticlesModule,
    ComponentModule
  ]
})
export class PageHomeModule { }
