import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { MoveDirection, OutMode, Container, Engine } from 'tsparticles-engine';
import { loadFull } from 'tsparticles';
import { Title } from '@angular/platform-browser';
import { SpeakerDataModel, SponsorshipDataModel } from 'src/app/model/data.model';
import { SponsorshipData } from 'src/app/data/sponsors.data';
import { SpeakerData } from 'src/app/data/speakers.data';
import { registrationLink } from 'src/app/data/registration.data';


// declare var particlesJS: any;

interface timeComponents {
  secondsToDday: number;
  minutesToDday: number;
  hoursToDday: number;
  daysToDday: number;
}

@Component({
  selector: 'app-page-home',
  templateUrl: './page-home.component.html',
  styleUrls: ['./page-home.component.scss'],
})
export class PageHomeComponent implements OnInit {
  id = 'particles-js';
  days = 0;
  hours = 0;
  minutes = 0;
  seconds = 0;
  distance = 0;
  isCounting = true;
  _isBrowser: boolean;

  registrationLink = registrationLink;

  sponsorshipData: SponsorshipDataModel[] = SponsorshipData;
  diamondSponsors?: SponsorshipDataModel[];
  platinumSponsors?: SponsorshipDataModel[];
  insightsSponsors?: SponsorshipDataModel[];
  goldSponsors?: SponsorshipDataModel[];
  silverSponsors?: SponsorshipDataModel[];

  speakerProfiles: SpeakerDataModel[] = SpeakerData;

  constructor(@Inject(PLATFORM_ID) platformId: Object, private title: Title) {
    this._isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.title.setTitle('SATNAC | Home');

    // Load sponsor per levels
    this.diamondSponsors = SponsorshipData.filter((sponsor) => sponsor.level === "diamond")
    this.platinumSponsors = SponsorshipData.filter((sponsor) => sponsor.level === "platinum")
    this.insightsSponsors = SponsorshipData.filter((sponsor) => sponsor.level === "insights")
    this.goldSponsors = SponsorshipData.filter((sponsor) => sponsor.level === "gold")
    this.silverSponsors = SponsorshipData.filter((sponsor) => sponsor.level === "silver")
    //
  }

  particlesOptions = {
    fullScreen: {
      enable: false,
    },
    particles: {
      number: {
        value: 200,
        density: {
          enable: true,
          value_area: 3000,
        },
      },
      color: {
        value: '#C8D094',
      },
      shape: {
        type: 'circle',
        polygon: {
          nb_sides: 5,
        },
      },
      opacity: {
        value: 0.5,
        random: true,
        anim: {
          enable: false
        },
      },
      size: {
        value: 4,
        random: true,
        anim: {
          enable: true,
          speed: 5,
          size_min: 0.01,
          sync: false,
        },
      },
      line_linked: {
        enable: false,
      },
      move: {
        enable: true,
        speed: 1,
        direction: MoveDirection.none,
        random: true,
        straight: true,
        out_mode: OutMode.out,
        bounce: false,
        attract: {
          enable: false
        },
      },
    },
    detectRetina: true,
  };

  particlesLoaded(container: Container): void {
    // console.log(container);
  }

  async particlesInit(engine: Engine): Promise<void> {
    // console.log(engine);

    // Starting from 1.19.0 you can add custom presets or shape here, using the current tsParticles instance (main)
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }
}
