import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  isOpenOnMobile = window.innerWidth <= 992;
  constructor() {}

  ngOnInit(): void {}

  mobileNavShow() {
    if (this.isOpenOnMobile) {
      const element: HTMLElement = document.getElementsByClassName(
        'mobilebtn'
      )[0] as HTMLElement;
      element.click();
    }
  }

  closeMobileNav() {
    const mobileNav: HTMLElement = document.getElementById('navbarSupportedContent') as HTMLElement;
    mobileNav.classList.remove('show');
  }
}
